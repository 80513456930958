

.Base {
  color: white;
  text-align: left;
  font-family: sans-serif;
  font-size: 1.2em;
}

h1 {
  font-size: 1.4em;
}
a {
  color: white;
}
a:hover {
  color: yellow;
}

.step1 {
  background-color: #101d27;
  border-bottom: #666;
  padding: 5px;
  text-align: center;
  height: 30px;
  position: fixed;
  left: 0px;
  right: 0px;
  z-index: 10;
  
}
.step1 .title {
  background-color: #111;
  border-radius: 5px;
  display: inline-block;
  padding: 5px 10px;
  font-size: 1.5em;
  margin-top: 5px;
}

.step2 {
  background-color: #162835;
  min-height: 100vh;
  /*padding-top: 20px;
  padding-right: 30px;
  padding-left: 60%;
  padding-bottom: 20px;*/
  text-align: left;
}

.step2-right {
  padding-top: 58px;
  margin-left: 56%;
  margin-right: 20px;
  font-size: 1.2em;
}

.step3 {
  background-color: #d4d4d4;
  color: black;
  font-weight: normal;
  padding: 20px;
  position: relative;
}

.step3b {
  background-color: #bbb;
  color: black;
  font-weight: normal;
  
  padding-top: 20px;
  
  padding-left: 10px;
  padding-bottom: 20px;
  position: relative;
}

.step4 {
  background-color: #979797;
  min-height: 20px;
  padding-top: 20px;
  padding-right: 30px;
  padding-left: 10px;
  padding-bottom: 20px;
  position: relative;
  color: black;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
}

.step4 h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.3em;
}

.step5 {
  background-color: #162835;
  color: white;
  min-height: 20px;
  padding-top: 20px;
  padding-right: 30px;
  padding-left: 10px;
  padding-bottom: 20px;
  position: relative;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
}



.rightarea {
  position: absolute;
  top: 20px;
  right: 0;
  width: 40%;
  font-size: 1.3em;
}

img.screenshot {
  border: 2px solid #666;
  width: 50%;
  transform: perspective( 50em ) rotateY( 25deg );
}

.iframe-wrap { 
  position: absolute;
  top: 70px;
  left: 26px;
  height: calc(100vh - 110px);
  width: 50%;		
  /*margin-left: 30px;
  margin-right: 30px;*/
  /*transform:  scale(0.5, 0.6) translateX(-420px) translateY(-80px) perspective( 100em ) rotateY( 31deg );*/
  /*transform: perspective( 100em ) rotateY( 31deg ) scale(0.41, 0.6) translateX(-62em) translateY(-2em);*/
  border: 2px solid #335b77;
  /*padding: 10px 10px 30px 10px;*/
  background-color: #333;
}


@media only screen and (max-device-width: 480px) {
  .iframe-wrap {
    display: none;
  }
  .step2-right {
    margin-left: 20px;
  }
}
@media only screen and (max-device-width: 880px) {
  .iframe-wrap {
    display: none;
  }
  .step2-right {
    margin-left: 20px;
  }
}

.iframe-wrap iframe {
  height: 125%;
  width: 125%;
  border: 0px solid #333;
  transform: scale(0.80);
  transform-origin: 0 0;
}

/*
.iframe-bottom {
  text-align: center;
  color: white;
  padding-top: 5px;
  position: absolute;
  left: -2px;
  right: -2px;
  bottom: -50px;
  height: 25px;
  background-color: #162835;
  border: 2px solid #335b77;
}

.iframe-bottom button {
  font-size: 0.9em;
  padding: 2px 4px;
}
.iframe-bottom button:disabled {
  background-color: #ccc;
  color: #888;
}
*/

.iframe-placeholder > div {
  margin-top: 50%;
  text-align: center;
}

.drop-shadow{
  filter:drop-shadow(2px 2px 3px black);		
}
    
button {
  background-color: lime;
  color: black;
  border-radius: 5px;
  font-size: 1.0em;
  padding: 5px 10px;
  font-weight: bold;
}