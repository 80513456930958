.BypassAuthentication {
    color: black;
    background-color: #fff;
    text-align: left;
    padding: 20px;
    font-size: 1.2em;
}
.BypassAuthentication a {
    color: black;
    font-weight: bold;
}

.BypassAuthentication .code {
    color: white;
    background-color: #111;
    text-align: left;
    padding: 20px;
    margin: 8px 0;
}

.BypassAuthentication .top-pad {
    margin-top: 20px;
}

.BypassAuthentication .left-pad {
    margin-left: 20px;
}

.BypassAuthentication .the-bad {
    color: red;
    font-weight: bold;
}
.BypassAuthentication .the-good {
    color: green;
    font-weight: bold;
}